<template>
  <div class="roleInfo">
    <div class="actionBar">
      <a-input-search
        size="large"
        v-model="listParams.keyword"
        placeholder="请输入关键词搜索"
        enter-button="查询"
        style="width: 350px"
        allowClear
        @search="onSearch"
      />
      <a-button
        icon="plus"
        type="primary"
        @click="showModal"
        size="large"
        v-if="handLists.top?handLists.top.indexOf('add')!=-1:false"
      >
        添加角色</a-button
      >
    </div>
    <a-modal
      :title="modalTitle"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="handleCancel"
      okText="确定"
      cancelText="取消"
    >
      <a-form
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        :from="addFromData"
      >
        <a-form-item label="角色名称">
          <a-input v-model="addFromData.name" />
        </a-form-item>
        <a-form-item label="角色简介">
          <a-input v-model="addFromData.description" />
        </a-form-item>
        <a-form-item label="角色权限">
          <a-tree
            :style="{ maxHeight: '400px', overflowY: 'scroll' }"
            v-model="addFromData.ids"
            checkable
            defaultExpandAll
            :auto-expand-parent="autoExpandParent"
            :tree-data="addMenu"
            :replaceFields="{ key: 'id' }"
          />
        </a-form-item>
      </a-form>
    </a-modal>
    <a-table
      :columns="columns"
      :data-source="data"
      rowKey="id"
      :pagination="{
        total: count,
        current: listParams.page,
        pageSize: listParams.limit,
        showTotal: (total) => `共 ${total} 条`,
      }"
      @change="pageChange"
    >
      <span slot="index" slot-scope="text, record, index">
        {{ index }}
      </span>
      <span slot="action" slot-scope="text, record">
        <FontIcon
          @click.native="editMenu(record)"
          title="修改"
          type="icon-xiugai"
          size="22"
          v-if="handLists.right?handLists.right.indexOf('edit')!=-1:false"
        ></FontIcon>
        <a-divider type="vertical" v-if="handLists.right?handLists.right.indexOf('edit')!=-1:false"/>
        <a-popconfirm
          title="确定删除？"
          ok-text="确定"
          cancel-text="取消"
          @confirm="confirm(record)"
          v-if="handLists.right?handLists.right.indexOf('del')!=-1:false"
        >
          <FontIcon title="删除" type="icon-lajitong" size="22"></FontIcon>
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>

<script>
import {
  roleIndex,
  menuMenuList,
  roleDataSave,
  roleDelete,
  roleDetail,
} from "@/apis/index";
const columns = [
  {
    title: "角色ID",
    dataIndex: "id",
    scopedSlots: { customRender: "id" },
    align: "center",
    width: 120,
  },
  {
    title: "角色名称",
    dataIndex: "name",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "角色简介",
    dataIndex: "description",
    scopedSlots: { customRender: "description" },
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

export default {
  data() {
    return {
      columns,
      data: [],
      visible: false,
      confirmLoading: false,
      modalTitle: "",
      addFromData: {},
      addMenu: [],
      listParams: {
        page: 1,
        limit: 10,
        keyword: "",
      },
      count: 0,
      autoExpandParent: true,

      handLists: this.$store.state.menuHandleList,
    };
  },
  computed: {
    handList() {
      return this.$store.state.menuHandleList;
    },
  },
  watch: {
    handList(val) {
      this.handLists = val;
    },
  },
  created() {
    this.getRoleIndex();
    this.getMenuMenuList();
  },
  methods: {
    getMenuMenuList() {
      menuMenuList().then((res) => {
        this.addMenu = res.data;
      });
    },
    showModal() {
      this.modalTitle = "新增角色";
      (this.addFromData = {
        id: "",
        name: "",
        description: "",
        ids: [],
      }),
        (this.visible = true);
    },
    handleOk(e) {
      this.confirmLoading = true;
      roleDataSave(this.addFromData).then((res) => {
        if (res.code == 1) {
          this.getRoleIndex();
          this.visible = false;
        }
        this.confirmLoading = false;
      });
    },
    handleCancel(e) {
      this.visible = false;
    },
    editMenu(record) {
      roleDetail({ id: record.id }).then((res) => {
        if (res.code == 1) {
          this.modalTitle = "修改角色信息";
          this.addFromData = {
            id: res.data.id,
            name: res.data.name,
            description: res.data.description,
            ids: res.data.show_menu_ids,
          };
          this.visible = true;
        }
      });
    },
    confirm(rec) {
      roleDelete({ id: rec.id }).then((res) => {
        if (res.code == 1) {
          this.$message.success("删除成功");
          this.getRoleIndex();
        }
      });
    },
    pageChange(a) {
      this.listParams.page = a.current;
      this.getRoleIndex();
    },
    getRoleIndex() {
      roleIndex(this.listParams).then((res) => {
        if (res.data.list.length == 0 && this.listParams.page > 1) {
          this.listParams.page--;
          this.getRoleIndex();
        } else {
          this.data = res.data.list;
          this.count = res.data.count;
        }
      });
    },
    onSearch(value) {
      this.listParams.page = 1;
      this.getRoleIndex();
    },
  },
};
</script>

<style lang="less" scoped>
.roleInfo {
  .actionBar {
    padding: 30px 0;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
  }
}
</style>
